import React from 'react';
import { useTranslation } from 'next-i18next';
import { Text, RadioCardGroup } from '@petrus/ui-library';

import { AnimalSpeciesEnum } from 'graphql/generated/graphql-request';
import { SpecieAvatar } from './SpecieAvatar';

type Props = {
  id: string;
  error?: string;
};

const SpeciesSelect = ({ id, error }: Props) => {
  const { t } = useTranslation('common');

  return (
    <RadioCardGroup
      id={id}
      orientation="horizontal"
      label={t('domain.animal.form.species')}
      options={[AnimalSpeciesEnum.DOG, AnimalSpeciesEnum.CAT]}
      className="w-full"
      error={error}
      required
      render={(option: AnimalSpeciesEnum) => (
        <div className="flex w-full flex-row items-center cursor-pointer">
          <SpecieAvatar speice={option} />
          <Text className="pl-4 text-space">{t(`domain.animal.species.${option}`)}</Text>
        </div>
      )}
    />
  );
};

export { SpeciesSelect };
export type { Props };

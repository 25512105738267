import React from 'react';
import { SpeiceIcon } from '@petrus/ui-library';

import { AnimalSpeciesEnum, AnimalType } from 'graphql/generated/graphql-request';

type Props = {
  speice?: AnimalType | AnimalSpeciesEnum;
};

const SpecieAvatarComponent = ({ speice }: Props) => {
  if (!speice) {
    return null;
  }

  return (
    <div className="rounded-full p-2 h-12 w-12 bg-blush flex items-center justify-center">
      <SpeiceIcon speice={speice} className="fill-space" />
    </div>
  );
};

const Skeleton = () => <div className="h-12 w-12 bg-gray-200 rounded-full" />;

const SpecieAvatar = Object.assign(SpecieAvatarComponent, {
  Skeleton,
});

export { SpecieAvatar };
export type { Props };

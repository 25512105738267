import React, { useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import { Heading, Text } from '@petrus/ui-library';

import * as dateUtils from 'common/utils/date.utils';
import { AnimalFragment } from 'graphql/generated/graphql-request';
import { SpecieAvatar } from './SpecieAvatar';

type Props = {
  animal: AnimalFragment;
  withDetails?: boolean;
};

const AnimalInfoComponent = ({ animal, withDetails = false }: Props) => {
  const { t } = useTranslation('common');
  const text = useMemo(() => {
    const age = dateUtils.getAgeFromBithday(animal.dob);
    const weight = animal.weight ? animal.weight : 0;
    const speiceText = t(`domain.animal.genders.species.${animal.type}.${animal.gender}`);
    const ageText = age ? `, ${t('domain.animal.values.age', { count: age })}` : '';
    const weightText = weight ? `, ${t('domain.animal.values.weight', { count: weight })}` : '';

    return `${speiceText}${ageText}${weightText}`;
  }, [animal]);

  return (
    <div className="w-full flex items-center gap-3">
      <SpecieAvatar speice={animal?.type} />
      <div className="flex-1 flex items-center justify-between">
        <div className="inline-flex flex-col space-y-1">
          <div className="flex justify-between">
            <Heading as="h4">{animal.name}</Heading>
          </div>
          {withDetails && (
            <Text className="block" size="tiny">
              {text}
            </Text>
          )}
        </div>
      </div>
    </div>
  );
};

type SkeletonProps = {
  withDetails?: boolean;
};

const Skeleton = ({ withDetails = false }: SkeletonProps) => (
  <div className="w-full flex items-center gap-3">
    <SpecieAvatar.Skeleton />
    <div className="flex flex-col space-y-4">
      <div className="w-64 h-3 bg-gray-300 rounded-full" />
      {withDetails && <div className="w-32 h-2 bg-gray-200 rounded-full" />}
    </div>
  </div>
);

const AnimalInfo = Object.assign(AnimalInfoComponent, {
  Skeleton,
});

export { AnimalInfo };
export type { Props };

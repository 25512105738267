import React from 'react';
import { useTranslation } from 'next-i18next';
import { Text, RadioCardGroup } from '@petrus/ui-library';

import { AnimalGendersEnum } from 'graphql/generated/graphql-request';
import { GenderAvatar } from './GenderAvatar';

type Props = {
  id: string;
  error?: string;
};

const GenderSelect = ({ id, error }: Props) => {
  const { t } = useTranslation('common');

  return (
    <RadioCardGroup
      id={id}
      orientation="horizontal"
      label={t('domain.animal.form.gender')}
      options={[AnimalGendersEnum.MALE, AnimalGendersEnum.FEMALE]}
      className="w-full"
      error={error}
      required
      render={(option: AnimalGendersEnum) => (
        <div className="flex w-full flex-row items-center cursor-pointer">
          <GenderAvatar gender={option} />
          <Text className="pl-4 text-space">{t(`domain.animal.genders.${option}`)}</Text>
        </div>
      )}
    />
  );
};

export { GenderSelect };
export type { Props };

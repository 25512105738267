import React, { useMemo } from 'react';
import { Text } from '@petrus/ui-library';

import { LocationFragment } from 'graphql/generated/graphql-request';

type Props = {
  location?: LocationFragment;
};

const LocationAddress = ({ location }: Props) => {
  const text = useMemo(() => {
    if (!location) {
      return '';
    }

    return `${location.streetAddress}, ${location.zipCode} ${location.city}`;
  }, [location]);

  return <Text size="tiny">{text}</Text>;
};

export { LocationAddress };
export type { Props };

var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};

// src/AddToCalendarButton.tsx
import Icons from "@petrus/ui-icons";
import {
  Button,
  Text,
  AccordionRoot,
  AccordionItem,
  AccordionHeader,
  AccordionContent
} from "@petrus/ui-library";

// src/lib/CalendarProvider.tsx
import React from "react";
import { jsx } from "react/jsx-runtime";
var CalendarContext = React.createContext({});
var useCalendarState = () => React.useContext(CalendarContext);
var CalendarProvider = ({ event, children }) => {
  return /* @__PURE__ */ jsx(CalendarContext.Provider, { value: { event }, children });
};

// src/lib/AddToCalendar.tsx
import { jsx as jsx2 } from "react/jsx-runtime";
var AddToCalendar = ({ info, children }) => {
  return /* @__PURE__ */ jsx2(CalendarProvider, { event: info, children });
};

// src/lib/CalendarButton.tsx
import { useCallback } from "react";

// src/lib/Clone.tsx
import React2 from "react";
function mergeProps(slotProps, childProps) {
  const overrideProps = __spreadValues({}, childProps);
  for (const propName in childProps) {
    if (childProps.hasOwnProperty(propName)) {
      const slotPropValue = slotProps[propName];
      const childPropValue = childProps[propName];
      if (/^on[A-Z]/.test(propName)) {
        if (slotPropValue && childPropValue) {
          overrideProps[propName] = (...args) => {
            childPropValue(...args);
            slotPropValue(...args);
          };
        } else if (slotPropValue) {
          overrideProps[propName] = slotPropValue;
        }
      } else if (propName === "className") {
        overrideProps[propName] = [slotPropValue, childPropValue].filter(Boolean).join(" ");
      }
    }
  }
  return __spreadValues(__spreadValues({}, slotProps), overrideProps);
}
var Clone = (props) => {
  const _a = props, { children } = _a, slotProps = __objRest(_a, ["children"]);
  if (React2.isValidElement(children)) {
    return React2.cloneElement(children, mergeProps(slotProps, children.props));
  }
  return React2.Children.count(children) > 1 ? React2.Children.only(null) : null;
};
Clone.displayName = "Clone";

// src/lib/utils.ts
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);
var urls = {
  google: "https://calendar.google.com/calendar/render",
  outlook: "https://outlook.live.com/owa",
  yahoo: "https://calendar.yahoo.com",
  download: ""
};
var getUrl = (type, info) => {
  const startTime = stripTimezoneFromDate(info.startTime);
  const endTime = getEndTime(startTime, info.durationInMin);
  const fullInfo = {
    title: info.title,
    description: info.description,
    location: info.location,
    duration: info.durationInMin,
    startsAt: formatDateForCalendar(startTime),
    endsAt: formatDateForCalendar(endTime)
  };
  switch (type) {
    case "google":
      return makeGoogleCalendarUrl(fullInfo);
    case "outlook":
      return makeOutlookCalendarUrl(fullInfo);
    case "yahoo":
      return makeYahooCalendarUrl(fullInfo);
    default:
      return makeICSCalendarUrl(fullInfo);
  }
};
var stripTimezoneFromDate = (date) => {
  const hoursOffset = dayjs(date).utcOffset() / 60;
  return dayjs(date).subtract(hoursOffset, "hour");
};
var formatDateForCalendar = (date) => {
  return date.format("YYYYMMDDTHHmmss");
};
var formatDateForOutlook = (date) => {
  return dayjs(date).format("YYYY-MM-DDTHH:mm:ssZ").split("+")[0];
};
var getEndTime = (startTime, durationInMin) => {
  return startTime.add(durationInMin, "minutes");
};
var getRandomKey = () => {
  const randomKey = Math.floor(Math.random() * 999999999999).toString();
  const dateString = new Date().getTime().toString();
  return `${dateString}_${randomKey}`;
};
var makeDuration = (minutes) => {
  return `${`0${Math.floor(minutes / 60)}`.slice(-2)}${`0${minutes % 60}`.slice(-2)}`;
};
var makeTime = (time) => new Date(time).toISOString().replace(/[-:]|\.\d{3}/g, "");
var makeUrl = (base, query) => Object.keys(query).reduce((accum, key, index) => {
  const value = query[key];
  if (value && value !== null) {
    return `${accum}${index === 0 ? "?" : "&"}${key}=${encodeURIComponent(value)}`;
  }
  return accum;
}, base);
var makeGoogleCalendarUrl = (event) => makeUrl(urls.google, {
  action: "TEMPLATE",
  dates: `${event.startsAt}/${event.endsAt}`,
  location: event == null ? void 0 : event.location,
  text: event.title,
  details: event == null ? void 0 : event.description
});
var makeOutlookCalendarUrl = (event) => makeUrl(urls.outlook, {
  rru: "addevent",
  startdt: formatDateForOutlook(event.startsAt),
  enddt: formatDateForOutlook(event.endsAt),
  subject: event.title,
  location: event.location,
  body: event.description,
  allday: false,
  uid: getRandomKey(),
  path: "/calendar/view/Month"
});
var makeYahooCalendarUrl = (event) => makeUrl(urls.yahoo, {
  v: 60,
  view: "d",
  type: 20,
  title: event.title,
  st: makeTime(event.startsAt),
  dur: makeDuration(event.duration),
  desc: event.description,
  in_loc: event.location
});
var makeICSCalendarUrl = (event) => {
  const components = ["BEGIN:VCALENDAR", "VERSION:2.0", "CALSCALE:GREGORIAN"];
  if (typeof document !== "undefined") {
    components.push(`URL:${document.URL}`);
  }
  components.push(
    "BEGIN:VEVENT",
    `DTSTAMP:${formatDateForCalendar(dayjs())}`,
    `UID:${getRandomKey()}`,
    `DTSTART;TZID=Europe/Berlin:${event.startsAt}`,
    `DTEND;TZID=Europe/Berlin:${event.endsAt}`,
    `SUMMARY:${event.title}`,
    `DESCRIPTION:${event.description}`,
    `LOCATION:${event.location}`,
    "END:VEVENT",
    "END:VCALENDAR"
  );
  return encodeURI(`data:text/calendar;charset=utf8,${components.join("\n")}`);
};

// src/lib/CalendarButton.tsx
import { jsx as jsx3 } from "react/jsx-runtime";
var CalendarButton = (_a) => {
  var _b = _a, { type, asChild = false, children } = _b, props = __objRest(_b, ["type", "asChild", "children"]);
  const { event } = useCalendarState();
  const onClick = useCallback(() => {
    if (typeof window === "undefined") {
      return;
    }
    const url = getUrl(type, event);
    window.open(url, "_blank");
  }, [type, event]);
  const Comp = asChild ? Clone : "button";
  return /* @__PURE__ */ jsx3(Comp, __spreadProps(__spreadValues({}, props), { onClick: () => onClick(), children }));
};

// src/AddToCalendarButton.tsx
import { Fragment, jsx as jsx4, jsxs } from "react/jsx-runtime";
var AddToCalendarButton = ({ children, info, ctaText = "+ Legg til i kalender" }) => {
  return /* @__PURE__ */ jsx4(AddToCalendar, { info, children: /* @__PURE__ */ jsx4(AccordionRoot, { type: "single", collapsible: true, children: /* @__PURE__ */ jsxs(AccordionItem, { value: "add-to-calendar", children: [
    /* @__PURE__ */ jsx4(AccordionHeader, { children: children ? /* @__PURE__ */ jsx4(Fragment, { children }) : /* @__PURE__ */ jsx4(
      Text,
      {
        as: "span",
        className: "flex border-b-1 border-carmine text-carmine hover:border-transparent",
        children: ctaText
      }
    ) }),
    /* @__PURE__ */ jsx4(AccordionContent, { className: "mt-1", children: /* @__PURE__ */ jsxs("ul", { className: "flex", children: [
      /* @__PURE__ */ jsx4("li", { className: "m-1", children: /* @__PURE__ */ jsx4(CalendarButton, { type: "google", asChild: true, children: /* @__PURE__ */ jsx4(Button, { look: "unstyled", size: "tiny", children: /* @__PURE__ */ jsx4(Icons.Google, { className: "w-5 fill-carmine" }) }) }) }),
      /* @__PURE__ */ jsx4("li", { className: "m-1", children: /* @__PURE__ */ jsx4(CalendarButton, { type: "outlook", asChild: true, children: /* @__PURE__ */ jsx4(Button, { look: "unstyled", size: "tiny", children: /* @__PURE__ */ jsx4(Icons.MicrosoftOutlook, { className: "w-5 fill-carmine" }) }) }) }),
      /* @__PURE__ */ jsx4("li", { className: "m-1", children: /* @__PURE__ */ jsx4(CalendarButton, { type: "download", asChild: true, children: /* @__PURE__ */ jsx4(Button, { look: "unstyled", size: "tiny", children: /* @__PURE__ */ jsx4(Icons.Download, { className: "w-5 fill-carmine" }) }) }) })
    ] }) })
  ] }) }) });
};

// src/AddToCalendarLink.tsx
import { useState } from "react";
import Icons2 from "@petrus/ui-icons";
import { Button as Button2, Dialog, Text as Text2 } from "@petrus/ui-library";
import { Fragment as Fragment2, jsx as jsx5, jsxs as jsxs2 } from "react/jsx-runtime";
var AddToCalendarLink = ({
  children,
  info,
  ctaText = "+ Legg i kalender",
  modalText = "Velg kalendertype"
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const calendarButtonStyle = "flex flex-row gap-1 text-space border-1 border-space rounded-full pr-4 hover:underline";
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
  return /* @__PURE__ */ jsxs2(AddToCalendar, { info, children: [
    /* @__PURE__ */ jsx5(Button2, { look: "unstyled", size: "small", className: "w-auto p-0", onClick: () => setIsOpen(true), children: children ? /* @__PURE__ */ jsx5(Fragment2, { children }) : /* @__PURE__ */ jsx5(
      Text2,
      {
        as: "span",
        size: "tiny",
        className: "mr-auto flex border-b-1 border-space hover:border-transparent",
        children: ctaText
      }
    ) }),
    /* @__PURE__ */ jsx5(Dialog, { title: modalText, isOpen, onIsOpenChange: setIsOpen, children: /* @__PURE__ */ jsxs2("ul", { className: "flex", children: [
      /* @__PURE__ */ jsx5("li", { className: "m-1", children: /* @__PURE__ */ jsx5(CalendarButton, { type: "google", asChild: true, children: /* @__PURE__ */ jsxs2(Button2, { look: "unstyled", size: "tiny", className: calendarButtonStyle, children: [
        /* @__PURE__ */ jsx5(Icons2.Google, { className: "w-6 fill-space" }),
        /* @__PURE__ */ jsx5(Text2, { size: "tiny", children: "Google" })
      ] }) }) }),
      /* @__PURE__ */ jsx5("li", { className: "m-1", children: /* @__PURE__ */ jsx5(CalendarButton, { type: "outlook", asChild: true, children: /* @__PURE__ */ jsxs2(Button2, { look: "unstyled", size: "tiny", className: calendarButtonStyle, children: [
        /* @__PURE__ */ jsx5(Icons2.MicrosoftOutlook, { className: "w-6 fill-space" }),
        /* @__PURE__ */ jsx5(Text2, { size: "tiny", children: "Outlook" })
      ] }) }) }),
      isMobile && /* @__PURE__ */ jsx5("li", { className: "m-1", children: /* @__PURE__ */ jsx5(CalendarButton, { type: "download", asChild: true, children: /* @__PURE__ */ jsxs2(Button2, { look: "unstyled", size: "tiny", className: calendarButtonStyle, children: [
        /* @__PURE__ */ jsx5(Icons2.Download, { className: "w-6 fill-space" }),
        /* @__PURE__ */ jsx5(Text2, { size: "tiny", children: "download" })
      ] }) }) })
    ] }) })
  ] });
};
export {
  AddToCalendarButton,
  AddToCalendarLink
};

import * as Yup from 'yup';
import { getPhoneNumberFormatted } from '@askeladden/phonenumber';
import { AnimalGendersEnum, AnimalSpeciesEnum } from 'graphql/generated/graphql-request';

export const otp = Yup.string().min(4, 'forms.validation.otp');

export const phone = Yup.string().test(
  'phone-number-valid',
  'forms.validation.phone',
  (input) => input && !!getPhoneNumberFormatted(input),
);

export const name = Yup.string()
  .min(2, 'forms.validation.name.min')
  .max(80, 'forms.validation.name.max');

export const email = Yup.string().email('forms.validation.email.invalid');

export const streetAddress = Yup.string()
  .min(2, 'forms.validation.streetAddress.min')
  .required('forms.validation.streetAddress.required');

export const zipCode = Yup.string()
  .matches(/^\d{4,5}( )*$/, 'forms.validation.zipCode.match')
  .required('forms.validation.zipCode.required');

export const city = Yup.string().min(2, 'forms.validation.city.min');

export const animalType = Yup.mixed<AnimalSpeciesEnum>().oneOf(
  Object.values(AnimalSpeciesEnum),
  'forms.validation.specie',
);

export const animalGender = Yup.mixed<AnimalGendersEnum>().oneOf(
  Object.values(AnimalGendersEnum),
  'forms.validation.gender',
);

export const dob = Yup.string()
  .required()
  .matches(/^([0-2][0-9]|3[0-1])-(0[1-9]|1[0-2])-([0-9]{2})$/, 'forms.validation.date.invalid');

export const note = Yup.string().required('forms.validation.note.required');

import React from 'react';
import { useTranslation } from 'next-i18next';
import { FormProvider, useForm, Input, Btn, DateInput, Alert } from '@petrus/ui-library';
import * as yup from 'yup';
import { useYupResolver } from '@petrus/ui-hooks';
import { Assign, ObjectShape } from 'yup/lib/object';
import { ControlledAnimal } from '../types';
import { name, animalType, dob, animalGender } from '../../../common/helpers/yup/validation';
import { GenderSelect } from './GenderSelect';
import { SpeciesSelect } from './SpeciesSelect';

type Props = {
  onSubmit: (values: ControlledAnimal) => void;
  submitText: string;
  onAbort?: () => void;
  loading?: boolean;
  error?: string;
  defaultValues?: ControlledAnimal & { note?: string };
  additionalSchema?: yup.ObjectSchema<Assign<ObjectShape, { [key: string]: any }>>;
} & Omit<React.HTMLAttributes<HTMLElement>, 'onSubmit'>;

const schema = yup.object().shape({
  type: animalType,
  gender: animalGender,
  name,
  dob,
});

const AnimalForm = ({
  submitText,
  defaultValues,
  onSubmit,
  onAbort,
  error,
  loading = false,
  children = null,
  additionalSchema = yup.object().shape({}),
}: Props) => {
  const { t } = useTranslation('common');
  const resolver = useYupResolver(schema.concat(additionalSchema));

  const methods = useForm({
    defaultValues,
    resolver,
    mode: 'onChange',
  });
  const {
    formState: { errors, isDirty, isValid },
  } = methods;

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} className="space-y-4">
        <Alert type="danger" hasError={!!error}>
          {error}
        </Alert>
        <SpeciesSelect id="type" error={t(errors.type?.message.toString())} />
        <GenderSelect id="gender" error={t(errors.gender?.message.toString())} />
        <Input
          id="name"
          required
          placeholder="Ada"
          label={t('domain.animal.form.name')}
          error={t(errors.name?.message.toString())}
        />
        <DateInput
          id="dob"
          required
          label={t('domain.animal.form.dob')}
          error={t(errors.dob?.message.toString())}
        />
        <div className="hidden">
          <Input
            id="weight"
            step="0.1"
            type="number"
            placeholder="8.2"
            label={t('domain.animal.form.weight')}
          />
        </div>
        {children as any}
        <div className="flex justify-end pt-2">
          {onAbort && (
            <Btn intent="simple" onClick={onAbort}>
              {t('cancel')}
            </Btn>
          )}
          <Btn intent="primary" type="submit" loading={loading} disabled={!isDirty || !isValid}>
            {submitText}
          </Btn>
        </div>
      </form>
    </FormProvider>
  );
};

export { AnimalForm };
export type { Props };

import React from 'react';
import { GenderIcon } from '@petrus/ui-library';

import { AnimalGendersEnum } from 'graphql/generated/graphql-request';

type Props = {
  gender?: AnimalGendersEnum;
};

const GenderAvatarComponent = ({ gender }: Props) => {
  if (!gender) {
    return null;
  }

  return (
    <div className="rounded-full p-2 h-12 w-12 bg-blush flex items-center justify-center">
      <GenderIcon gender={gender} className="fill-space" />
    </div>
  );
};

const Skeleton = () => <div className="h-12 w-12 bg-gray-200 rounded-full" />;

const GenderAvatar = Object.assign(GenderAvatarComponent, {
  Skeleton,
});

export { GenderAvatar };
export type { Props };
